// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'

import publishers from '@OptimusViews/publishers/store/reducer'
import searchCampaign from '@OptimusViews/searchCampaign/store/reducer'
import users from '@OptimusViews/manageUsers/store/reducer'
import logAction from '@OptimusViews/logAction/store/reducer'
import allLogAction from '@OptimusViews/allLogAction/store/reducer'

import brands from '@castifyViews/brands/store/reducer'
import statsBeaconsStats from '@castifyViews/stats/beaconsStats/store/reducer'
import rokuChannel from '@castifyViews/rokuChannels/store/reducer'
import videoLibrary from '@castifyViews/videoLibrary/store/reducer'
import playlist from '@castifyViews/playlist/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  navbar,
  layout,
  invoice,
  ecommerce,
  dataTables,
  
  users,
  publishers,
  searchCampaign,
  logAction,
  allLogAction,


  brands,
  statsBeaconsStats,
  rokuChannel,
  videoLibrary,
  playlist
})

export default rootReducer
