export const SysLogo = () => {
    if (window.location.host === "search.mars.media") {
        return require('@src/assets/images/logo/mars_logo.svg').default
    } else {
        return require('@src/assets/images/logo/optimus_studio.svg').default
    }
}

export const SysLogoMini = () => {
    if (window.location.host === "search.mars.media") {
        return require('@src/assets/images/logo/mars_logo_min_black.svg').default
    } else {
        return require('@src/assets/images/logo/optimus_studio_min.svg').default
    }
}

export const SysLogoWhite = () => {
    if (window.location.host === "search.mars.media") {
        return require('@src/assets/images/logo/mars_logo_white.svg').default
    } else {
        return require('@src/assets/images/logo/optimus_studio_white.svg').default
    }
}

export const SysLogoWhiteMini = () => {
    if (window.location.host === "search.mars.media") {
        return require('@src/assets/images/logo/mars_logo_min_white.svg').default
    } else {
        return require('@src/assets/images/logo/optimus_studio_min_white.svg').default
    }
}